/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    code: "code",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "data-structures",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#data-structures",
    "aria-label": "data structures permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Data Structures"), "\n", React.createElement(_components.p, null, "and Introduction to Linear and Non-Linear Data Structures"), "\n", React.createElement(_components.h2, {
    id: "introduction-to-data-structures",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction-to-data-structures",
    "aria-label": "introduction to data structures permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction to Data Structures"), "\n", React.createElement(_components.p, null, "Data Structures are an integral part of programming in any language. They are very important for storing, organizing, managing, analyzing and updating the data efficiently."), "\n", React.createElement(_components.p, null, "There are 2 Types of Data Structures :"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "1. Linear Data Structures :"), " In this, the data is stored in sequential manner, that is 1 after another. There is at max 1 preceding and succeeding element in Linear data structures.\nFor example, Arrays, Linked Lists, Stacks, Queues, Strings etc."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "2. Non-Linear Data Structures :"), " In this, the data is stored in such a way that there is no definite order. There can be any number of children.\nFor example, Trees and Graphs."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "We will only see the data structures included in ", React.createElement(_components.code, null, "std::collections"), " module in this section")), "\n", React.createElement(_components.h2, {
    id: "static-and-non-static-data-structures",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#static-and-non-static-data-structures",
    "aria-label": "static and non static data structures permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Static and Non-Static Data Structures"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "1. Static Data Structures :"), " These are data structures, that have been allocated ", React.createElement(_components.em, null, "fixed size of memory"), ". For example, arrays. We can not grow or shrink the memory allocated to an array.\n( We can do reallocation, that is allocate a new memory altogether, copy the contents from previous allocation, and free the previous allocation )"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "2. Dynamic Data Structures :"), " These are data structures that can be allocated memory at runtime. For example, Linked Lists. These are much slower in access time, but simpler and easier to write and maintain,\nespecially for applications like stacks, queues, trees etc."), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "I will keep this article short and to the point. We will see some built in data structures and how to use them in Rust in next articles."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Data Structures - Rust Programming",
    description: "Data Structures are an integral part of programming in any language. They are very important for storing, organizing, managing and analyzing the data. We will discuss data structures in Rust in this section"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
